import api from '@/lib/api'
import Vue from 'vue'

const state = {
  asset: null,
  assets: {},
  loading: false,
  error: null
}

const mutations = {
  SET_ASSETS (state, assets) {
    for (const asset of assets) {
      Vue.set(state.assets, asset.id, asset)
    }
  },
  SET_ASSET (state, { id, asset }) {
    state.assets[id] = asset
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_ERROR (state, error) {
    state.error = error
  },
  CLEAR_ERROR (state) {
    state.error = null
  },
  CLEAR_ASSET (state, id) {
    delete state.assets[id]
  }
}

const getters = {
  asset: state => id => state.assets[id],
  assets: state => {
    return Array.from(Object.values(state.assets))
  },
  loading: state => state.loading
}

const actions = {
  fetchAssets: async ({ commit }) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    try {
      const response = await api.get('assets')
      commit('SET_ASSETS', response.data.data)
    } catch (error) {
      commit('SET_ERROR', 'Failed to load asset data')
      console.error('API request failed:', error)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  fetchAsset: async ({ commit }, id) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    try {
      const response = await api.get(`assets/${id}`)
      commit('SET_ASSET', { id, asset: response.data.data })
    } catch (error) {
      commit('SET_ERROR', 'Failed to load asset data')
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
