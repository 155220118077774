<template>
  <l-map ref="map"
    :minZoom="1"
    :maxZoom="22"
    @ready="onMapLoaded"
    @dblclick="onMapDoubleTap"
    :style="height ? { height } : ''"
    :options="{
      zoomControl: false,
      rotateControl: false,
      touchRotate: false,
      shiftKeyRotate: false,
      attributionControl: false,
      rotate: true,
      fullscreenControl: showControls && {
        position: 'topright'
      }
    }"
  >
    <l-control>
      <edit-modal ref="editModal" />
    </l-control>
    <l-control v-if="progress" position="topLeft" class="leaflet-control progress-control">
      <d-card>
        <d-card-body>
          <div class="step">
            <div><strong>{{ progress.step || 'Please wait...' }}</strong></div>
          </div>
          <d-progress v-if="'percent' in progress" :value="progress.percent" striped animated />
          <d-progress v-else :value="100" striped animated />
          <d-button v-if="progress.cancel !== false" theme="light" class="float-right mt-4"
            @click.native.stop="progress = null">
            Cancel
          </d-button>
        </d-card-body>
      </d-card>
    </l-control>
    <l-control-info v-if="showControls && editable" position="topright"
      :visible="plan || legacyPlan"
      :isRefreshing="refreshing"
      @click:refresh="refreshMarkers"
      :labels="labels"
      :markerTypeFilter="markerTypeFilter"
      :markerLabelFilter="markerLabelFilter"
      @toggle:labels="labels = $event"
      @toggle:diagnostics="diag = $event"
      @filter="showFilterModal()"
    />
    <l-control-edit v-if="showControls && editable" ref="editControl" position="topright"
      :units="units"
      :visible="plan || legacyPlan"
      :mode="editMode"
      @change:mode="editMode = $event"
      @add:unit="unitAdd"
      @edit:unit="unitEdit"
      @update:unit="unitUpdate"
      @delete:unit="unitUpdate"
      @click:unit="onUnitTap"
    />
    <l-control-plan v-if="showControls && editable" position="topright"
      :mode="editMode"
      :apiKey="apiKey"
      :show="!!showPlan"
      @change:mode="onPlanToggle"
      @upload="upload"
      @move="move"
      @toggle:show="onShowToggle"
      @rotate="onPlanRotate"
    />
    <l-control v-if="showControls && editPlanMode" position="topleft">
      <place-autocomplete-field
        placeholder="Enter an an address, zipcode, or location"
        :api-key="apiKey"
        @autocomplete-select="move"
      />
    </l-control>
    <l-control-floors v-else-if="showControls && floors && floors.length" position="topleft"
      :floors="floors"
      :id="floorId"
      @change="onFloorChange"
    />
    <l-tile-layer v-if="showStreets" :url="`http://mt0.google.com/vt/lyrs=${googleLayers}&x={x}&y={y}&z={z}`" :zIndex="1" :options="{maxZoom: 24}" key="streetTiles" />
    <l-tile-layer v-if="plan" v-bind="plan" ref="tileLayer" :zIndex="2" :options="{maxZoom: 24}" key="planTiles" />
    <l-distortable-image-overlay ref="editPlanLayer" v-if="editPlan" v-bind="editPlan" :key="key" :zIndex="2000"
      @dragstart="onPlanEdit"
      @edit="onPlanEdit"
    />
    <l-circle v-for="tracker in trackersToDisplay" :key="`${tracker.latlng.lat},${tracker.latlng.lng}`"
      :lat-lng="tracker.latlng"
      :radius="tracker.radius || 4"
      :stroke="false"
      :fillColor="tracker.color || 'red'"
    />
    <l-circle v-if="tracker && tracker.latlng && tracker.type === 'asset'"
      :lat-lng="tracker.latlng"
      :radius="tracker.radius || 4"
      :stroke="false"
      fillColor="green"
    />
    <l-marker v-if="trackerFine && trackerFine.latlng"
      :lat-lng="trackerFine.latlng"
      :icon="trackingMarkerIcon(trackerFine.iconParams)"
      :draggable="false"
    />
    <l-marker v-for="tracker in trackersToDisplay.filter(t => t.type === 'person')" :key="`${tracker.latlng.lat},${tracker.latlng.lng}`"
      :lat-lng="tracker.latlng"
      :icon="personIcon(tracker)"
    >
      <l-tooltip :key="`${tracker.latlng.lat},${tracker.latlng.lng}`">
        <p class="font-weight-bold pb-0 mb-0">{{ tracker.name }}</p>
        <p class="pb-0 mb-0">{{ tracker.details }}</p>
      </l-tooltip>
    </l-marker>
    <l-marker v-for="marker in markersToDisplay"
      :key="key + marker.id + editMarkersMode + labels"
      :lat-lng="marker.config.coordinates"
      :icon="markerIcon(marker)"
      :draggable="editMarkersMode"
      @click="onMarkerTap(marker, $event)"
      @dragstart="dragging = true"
      @dragend="dragging = false, markerMove(marker, ($event.target || {})._latlng || {})"
      @mouseover="hoverMarker = isGateway(marker) && marker"
      @mouseout="hoverMarker = false"
    >
      <l-tooltip :options="{ permanent: labels || (diag && diagDetailsGateway === marker.id) }" :key="marker.id + editMarkersMode + labels + diag + 'm'">
        <p class="font-weight-bold pb-0 mb-0" v-if="marker.config.location[levelDepth('area')]">{{ marker.config.location[levelDepth('area')].name }}</p>
        <p v-if="isGateway(marker)" class="pb-0 mb-0">{{ marker.id }}</p>
        <template v-if="!editMarkersMode && isGateway(marker)">
          <p v-if="isSafeMode(marker)" class="pb-0 mb-0">Safe Mode</p>
          <p v-else-if="marker.state.adminMode" class="pb-0 mb-0">Admin Mode</p>
          <p class="pb-0 mb-0">{{ hwLabel(marker) }}</p>
          <div v-if="diag && marker.state.diagnostics" >
            <div class="mt-0" v-html="diagnosticsSummary(marker)" />
            <div class="mt-1" v-if="diagDetailsGateway === marker.id">
              <div class="mt-0">
                <p class="font-weight-bold pb-0 mb-0">Button</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.button ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].button.percent} (${gatewayMetrics[marker.id].button.total} / ${gatewayMetrics[marker.id].button.count})` }}</p>
              </div>
              <div class="mt-1">
                <p class="font-weight-bold pb-0 mb-0">Coverage</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.coverage ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].coverage.percent} (${gatewayMetrics[marker.id].coverage.total} / ${gatewayMetrics[marker.id].coverage.count})` }}</p>
              </div>
              <div class="mt-1 mb-1">
                <p class="font-weight-bold pb-0 mb-0">Floor</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.floor ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].floor.percent} (${gatewayMetrics[marker.id].floor.total} / ${gatewayMetrics[marker.id].floor.count})` }}</p>
              </div>
              <div v-for="(n, i) of neighbors(marker).onFloor" :key="n.id" class="pb-0 mb-0">
                <p class="font-weight-bold mb-0 pb-0" v-if="i === 0">Last diagnostic on-floor</p>
                <p class="mb-0 pb-0">{{ n.id }}: {{ n.distance.toFixed(2) }}m</p>
              </div>
              <div v-for="(n, i) of neighbors(marker).offFloor" :key="n.id" class="pb-0 mb-0">
                <p class="font-weight-bold mb-0 pb-0 mt-1" v-if="i === 0">Last diagnostic off-floor</p>
                <p class="mb-0 pb-0">{{ n.id }}: {{ n.distance.toFixed(2) }}m</p>
              </div>
            </div>
          </div>
        </template>
        <p v-if="marker.config.labelsString" class="pb-0 mb-0">{{ marker.config.labelsString }}</p>
        <p v-if="marker.config.notes" class="pb-0 mb-0">{{ marker.config.notes }}</p>
        <p v-if="marker.state && marker.state.status === 'disconnected'" class="pb-0 mb-0">{{ 'Last Seen: ' + time(marker.state.updatedAt, true) }}</p>
      </l-tooltip>
    </l-marker>
    <l-feature-group ref="toolbar">
      <l-popup :options="{closeButton:false, offset:[0,0]}">
        <d-button-toolbar>
          <d-button-group size="small">
            <d-button theme="light" @click="toolbarAction('edit')"><i class="fa fa-pencil-alt" /></d-button>
            <d-button theme="light" @click="toolbarAction('delete')"><i class="fa fa-trash-alt" /></d-button>
          </d-button-group>
        </d-button-toolbar>
      </l-popup>
    </l-feature-group>
    <template v-if="diag">
      <l-polyline v-for="n of neighbors(hoverMarker).onFloor"
        :key="n.id"
        :lat-lngs="n.line"
        dashArray="4 8"
        color="green" />
    </template>
  </l-map>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/lib/api'
import utils from '@/mixins/utils'
import axios from 'axios'
import * as parse from 'xml-parser'
import {
  LMap,
  LMarker,
  LCircle,
  LPopup,
  LTooltip,
  LFeatureGroup,
  LTileLayer,
  LControl,
  LPolyline
} from 'vue2-leaflet'
import {
  LControlEdit,
  LControlPlan,
  LControlInfo,
  LControlFloors,
  LDistortableImageOverlay
} from '@/components/map'
import EditModal from '@/components/EditModal'
import 'leaflet.awesome-markers'
import 'leaflet-fullscreen'
import '@vpulim/leaflet-rotate'
import config from '@/config'
import { markerLabels } from '../../../processor/src/lib/labels'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'

const BROWNOUT_RESET_CODE = 9
const BUTTON_PERCENT_THRESHOLD = 0.90
const COVERAGE_PERCENT_THRESHOLD = 0.90
const FLOOR_PERCENT_THRESHOLD = 0.51
const A_DAY_AGO = 24 * 3600 * 1000
const CDN_SIGNATURE_REFRESH_INTERVAL = 1000 * 60 * 30 // 30 minutes
const CDN_SIGNATURE_CHECK_INTERVAL = 1000 * 30 // 30 seconds

const L = window.L
const planUrlBase = 'https://images.storage.smplabs.com/tiles'

export default {
  name: 'floor-plan',
  mixins: [utils],
  props: {
    location: {
      type: Array
    },
    height: {
      type: String
    },
    showControls: {
      type: Boolean
    },
    showMarkers: {
      type: Boolean
    },
    trackers: {
      type: Array,
      default: () => []
    },
    trackerFine: {
      type: Object
    },
    gatewayFilter: {
      type: String,
      default: 'all'
    },
    disconnectedDays: {
      type: Number,
      default: 2
    },
    gpsMode: {
      type: Boolean
    },
    googleLayers: {
      type: String,
      default: 'm'
    }
  },
  components: {
    LMap,
    LMarker,
    LCircle,
    LControl,
    LPolyline,
    LPopup,
    LTooltip,
    LFeatureGroup,
    LTileLayer,
    LControlEdit,
    LControlPlan,
    LControlInfo,
    LControlFloors,
    LDistortableImageOverlay,
    EditModal
  },
  data () {
    return {
      key: 0,
      apiKey: config.google.apiKey,
      map: null,
      floorId: null,
      units: [],
      trackerMarker: null,
      markers: [],
      markersMap: {},
      actions: null,
      dragging: false,
      loading: false,
      refreshing: false,
      hoverMarker: false,
      bounds: null,
      imageLoaded: false,
      viewport: null,
      diag: false,
      diagDetailsGateway: null,
      editable: this.$can('update', 'FloorPlan'),
      editMode: null,
      toolbarTarget: null,
      toolbarOffset: 0,
      showStreets: false,
      plan: null,
      editPlan: null,
      showPlan: true,
      planEdited: false,
      planRotated: false,
      labels: false,
      zoom: 20,
      gatewayMetrics: {},
      markerLabels: markerLabels, // for access in template above
      markerTypeFilter: ['all'],
      markerLabelFilter: ['all'],
      progress: null,
      signature: null,
      keepBounds: false
    }
  },
  watch: {
    async location () {
      this.updatedLocation()
    },
    trackers () {
      this.updatedTrackers()
    },
    async diag () {
      await this.fetchGatewayMetrics()
      await this.refreshMarkers()
    },
    gatewayFilter () {
      this.markerTypeFilter = [this.gatewayFilter]
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('zones', [
      'locationTo',
      'zones',
      'zone',
      'subzones'
    ]),
    editMarkersMode () {
      return this.editMode === 'markers'
    },
    editUnitsMode () {
      if (this.editMode === 'units.edit' || this.editMode === 'units.add') {
        return this.editMode.split('.')[1]
      }
      return ''
    },
    editPlanMode () {
      return this.editMode === 'plan'
    },
    floors () {
      const buildingId = this.zoneIdAtLevel(this.location, 'building')
      if (buildingId) {
        const floors = this.subzones(buildingId)
        if (Array.isArray(floors)) {
          return floors
            .filter(floor => floor.config && typeof floor.config.number === 'number')
            .sort((a, b) => a.config.number - b.config.number)
        }
      }
      return []
    },
    markersToDisplay () {
      let markers = []
      if (this.markerTypeFilter.includes('all')) {
        markers = this.markers
      } else {
        if (this.markerTypeFilter.includes('markers')) {
          markers = markers.concat(this.markers.filter(m => m.state === undefined))
        }
        if (this.markerTypeFilter.includes('gateways')) {
          markers = markers.concat(this.markers.filter(m => m.state !== undefined))
        } else {
          const now = Date.now()
          if (this.markerTypeFilter.includes('connectedDays')) {
            markers = markers.concat(this.markers.filter(m => m.state && now - new Date(m.state.updatedAt).getTime() <= this.disconnectedDays * A_DAY_AGO))
          } else if (this.markerTypeFilter.includes('disconnected')) {
            markers = markers.concat(this.markers.filter(m => m.state && m.state.status === 'disconnected'))
          } else if (this.markerTypeFilter.includes('disconnectedDays')) {
            markers = markers.concat(this.markers.filter(m => m.state && m.state.status === 'disconnected' && now - new Date(m.state.updatedAt).getTime() > this.disconnectedDays * A_DAY_AGO))
          } else if (this.markerTypeFilter.includes('disconnectedRecent')) {
            markers = markers.concat(this.markers.filter(m => m.state && m.state.status === 'disconnected' && now - new Date(m.state.updatedAt).getTime() <= this.disconnectedDays * A_DAY_AGO))
          }
          if (this.markerTypeFilter.includes('safeMode')) {
            markers = markers.concat(this.markers.filter(m => this.isSafeMode(m)))
          }
          if (this.markerTypeFilter.includes('diagFail')) {
            markers = markers.concat(this.markers.filter(m => m.state !== undefined && !this.checkDiagnostics(m)))
          }
          if (this.markerTypeFilter.includes('healthy')) {
            markers = markers.concat(this.markers.filter(m => m.state !== undefined && m.state.status !== 'disconnected' && !this.isSafeMode(m) && !this.checkDiagnostics(m)))
          }
        }
      }
      // If a label filter is defined, apply that to the results of the above.
      if (this.markerLabelFilter.length && !this.markerLabelFilter.includes('all')) {
        let filteredMarkers = []
        if (this.markerLabelFilter.includes('labeled')) {
          markers = filteredMarkers.concat(markers.filter(m => !(m.config.labels || []).length))
        }
        if (this.markerLabelFilter.includes('notes')) {
          filteredMarkers = filteredMarkers.concat(markers.filter(m => !!m.config.notes))
        }
        if (this.markerLabelFilter.includes('unlabeled')) {
          filteredMarkers = filteredMarkers.concat(markers.filter(m => !(m.config.labels || []).length && !m.config.notes))
        }
        const specificLabelFilters = this.markerLabelFilter.filter(f => !!markerLabels[f])
        if (specificLabelFilters.length) {
          filteredMarkers = filteredMarkers.concat(markers.filter(m => specificLabelFilters.some(f => (m.config.labels || []).includes(f))))
        }
        markers = filteredMarkers
      }
      const uniqueMarkers = new Set(markers)
      return Array.from(uniqueMarkers.values())
    },
    trackersToDisplay () {
      return this.trackers.filter(t => t.latlng && ['alert', 'person'].includes(t.type))
    }
  },
  created () {
    if (this.gpsMode) {
      this.showStreets = true
    }
    if (this.editable) {
      this.refreshInterval = setInterval(() => this.refreshMarkers(), 5000)
    }
    this.signatureInterval = setInterval(() => {
      this.updateSignature()
    }, CDN_SIGNATURE_CHECK_INTERVAL)
    this.markerTypeFilter = [this.gatewayFilter]
  },
  destroyed () {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
    if (this.signatureInterval) {
      clearInterval(this.signatureInterval)
    }
  },
  methods: {
    ...mapActions('zones', {
      addZone: 'add',
      updateZone: 'update',
      deleteZone: 'delete',
      loadPath: 'loadPath'
    }),
    async initialize () {
      await this.updateSignature()
      await this.updatedLocation()
      await this.updatedTrackers()
    },
    async updateSignature () {
      try {
        if (!this.signature || this.signature.lastUpdated + CDN_SIGNATURE_REFRESH_INTERVAL < Date.now()) {
          const { data } = await api.get('storage/signature/images')
          if (data) {
            this.signature = {
              value: data,
              lastUpdated: Date.now()
            }
            if (this.plan && this.plan.tmsUrl) {
              const tileLayer = this.$refs.tileLayer && this.$refs.tileLayer.mapObject
              if (tileLayer) {
                tileLayer.setUrl(this.plan.tmsUrl + `?${this.signature.value}`, true)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
      return this.signature && this.signature.value
    },
    getSignature () {
      return this.updateSignature()
    },
    prepareToExit (next) {
      const editControl = this.$refs.editControl
      if (editControl) {
        editControl.prepareToExit(next)
      } else {
        next()
      }
    },
    async onMapLoaded (map) {
      map.doubleClickZoom.disable()
      this.map = map
      return this.initialize()
    },
    onMapDoubleTap (event) {
      if (this.editMarkersMode) {
        this.markerAdd(event.latlng)
      } else if (this.editMode === 'units') {
        this.$refs.editControl.toggleDrawMode()
      }
    },
    onPlanToggle (mode) {
      this.editMode = mode
      this.$nextTick(() => {
        if (mode === 'plan') {
          this.showPlanEditor()
        } else {
          this.hidePlanEditor()
        }
      })
    },
    onShowToggle (show) {
      const tileLayer = this.$refs.tileLayer && this.$refs.tileLayer.mapObject
      if (show) {
        tileLayer && tileLayer.addTo(this.map)
      } else {
        tileLayer && tileLayer.remove()
      }
      this.showPlan = show
    },
    onFloorChange (floor) {
      this.updatedFloor(floor.id)
    },
    async showPlanEditor (id) {
      if (!this.editPlanMode || !this.floorId) {
        this.editPlan = null
        return
      }
      this.showStreets = true
      this.planEdited = false
      this.planRotated = false
      let corners
      if (!id) {
        const floor = this.zone(this.floorId)
        const geometry = floor.config.geometry
        id = floor.config.planId
        if (geometry && geometry.coordinates) {
          corners = geometry.coordinates[0].slice(0, 4).map(([lng, lat]) => ({ lat, lng }))
          corners = [corners[0], corners[1], corners[3], corners[2]]
        }
      }
      if (!id) {
        this.editPlan = null
        return
      }
      const signature = await this.getSignature()
      this.key++
      this.editPlan = {
        id,
        url: `${planUrlBase}/${this.floorId}/${id}/thumbnail.jpg?${signature}`,
        selected: true,
        editable: true,
        suppressToolbar: false,
        ...(corners && { corners })
      }
      this.onShowToggle(false)
    },
    async hidePlanEditor () {
      this.showStreets = false
      const text = this.planRotated && !this.planEdited
        ? 'Save rotation changes?'
        : 'Save changes to floor plan?'
      if ((this.planEdited || this.planRotated) && confirm(text)) {
        if (this.planEdited) {
          await this.generateTiles()
          this.planEdited = false
        }
        if (this.planRotated) {
          await this.updateBearing(this.map._bearing * L.DomUtil.RAD_TO_DEG)
          this.planRotated = false
        }
      }
      this.$nextTick(() => {
        this.editPlan = null
        this.updatePlan()
      })
      this.onShowToggle(true)
    },
    async updatePlan () {
      const zone = this.zone(this.floorId)
      if (!zone || !zone.config || !zone.config.planId || zone.level !== 'floor') {
        this.plan = null
        return
      }

      // try loading plan
      try {
        const signature = await this.getSignature()
        const planUrl = `${planUrlBase}/${this.floorId}/${zone.config.planId}`
        const xmlUrl = planUrl + '/tilemapresource.xml' + `?${signature}`
        const { data: metadata } = await axios.get(xmlUrl)
        const meta = parse(metadata)
        const { minx, miny, maxx, maxy, flipy } = meta.root.children.find(c => c.name === 'BoundingBox').attributes
        const tmsUrl = planUrl + (flipy === 'true' ? '/{z}/{x}/{y}.png' : '/{z}/{x}/{-y}.png')
        this.bounds = L.latLngBounds()
          .extend([Number(miny), Number(minx)]).extend([Number(maxy), Number(maxx)])
        this.updatedBounds()
        this.map.setBearing(zone.config.planBearing || 0)
        this.plan = {
          url: tmsUrl + `?${signature}`,
          tmsUrl: tmsUrl,
          tileSize: 256,
          options: { minZoom: 18, maxZoom: 22 }
        }
      } catch (error) {
        this.plan = null
        if (error.response && error.response.status !== 404) {
          console.log(error)
        }
      }
    },
    async updatedLocation () {
      await this.loadPath({ zone: this.zoneId(this.location) })
      let floorId = this.zoneIdAtLevel(this.location, 'floor')
      if (!floorId && this.floors.length) {
        floorId = this.floors[0].id
      }
      if (floorId !== this.floorId) {
        return this.updatedFloor(floorId)
      }
    },
    async updatedFloor (floorId) {
      if (!this.map) return
      if (floorId !== this.floorId) {
        this.key++
      }
      this.floorId = floorId
      try {
        this.loading = true
        if (this.floorId) {
          await this.loadPath({ zone: this.floorId })
          this.units = this.subzones(this.floorId)
        } else {
          this.units = []
        }
        await this.clearMarkers()
        await this.updatePlan()
        await this.fetchGatewayMetrics()
        await this.refreshMarkers()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    updatedBounds (options = {}) {
      if (!this.map || (this.gpsMode && this.keepBounds)) return
      if (this.trackersToDisplay.length) {
        const trackers = this.trackersToDisplay
        if (trackers.length === 1) {
          const tracker = trackers[0]
          if (tracker && tracker.latlng) {
            if (this.gpsMode) {
              this.map.setView(tracker.latlng, 19, options)
            } else {
              this.map.flyTo(tracker.latlng, tracker.zoom || 21, options)
            }
          }
        } else {
          try {
            const bounds = [
              [minBy(trackers, 'latlng.lat').latlng.lat, minBy(trackers, 'latlng.lng').latlng.lng],
              [maxBy(trackers, 'latlng.lat').latlng.lat, maxBy(trackers, 'latlng.lng').latlng.lng]
            ]
            this.fit(bounds, { duration: 0 })
          } catch (error) {
            console.log(error)
          }
        }
      } else {
        this.fit(this.bounds, { duration: 0 })
      }
      this.keepBounds = true
    },
    updatedTrackers () {
      this.updatedBounds({ animate: true })
    },
    async clearMarkers () {
      this.markers = []
      this.markersMap = {}
    },
    async refreshMarkers () {
      if (this.dragging || this.refreshing || !this.showMarkers) return
      if (!this.floorId) {
        this.clearMarkers()
        return
      }
      this.refreshing = true
      try {
        const { data: gateways } = await api.get('gateways', {
          params: {
            limit: 500,
            'config.state': { $in: ['deployed', 'ready_to_deploy'] },
            'config.location': this.floorId,
            'config.coordinates.lat': { $exists: true },
            'config.coordinates.lng': { $exists: true },
            'config.coordinates.alt': { $exists: true },
            ...(((this.zone(this.floorId) || {}).config || {}).category === 'outdoor' && { sku: 'NODE1', 'config.logistics.sku': { $in: ['NODE1', 'GWL1'] } })
          }
        })
        const refreshed = gateways.data
        const { data: markers } = await api.get('markers', {
          params: {
            limit: 500,
            'config.location': this.floorId
          }
        })
        refreshed.push(...markers.data.filter(m => m.config.coordinates))
        const now = Date.now()
        refreshed.forEach(m => {
          if (m.state && m.state.updatedAt) {
            m.isConnected = m.state && now - new Date(m.state.updatedAt).getTime() <= this.disconnectedDays * A_DAY_AGO
          }
        })
        this.markers = refreshed
        this.markersMap = {}
        refreshed.forEach(marker => { this.markersMap[marker.id] = marker })
      } catch (error) {
        console.log(error)
      } finally {
        this.refreshing = false
      }
    },
    updateMarker (marker) {
      const index = this.markers.findIndex(m => m.id === marker.id)
      if (index !== -1) {
        this.$set(this.markers, index, marker)
      }
    },
    isMarker (marker) {
      return marker && marker.state === undefined
    },
    isGateway (marker) {
      return marker && marker.state !== undefined
    },
    markerAddWithLocation ({ coordinates, location }, showLoader) {
      if (showLoader) this.loading = true
      return api.post('markers', { coordinates, location })
        .then(async ({ data }) => {
          await this.refreshMarkers()
          return data
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not add marker: ${error.reason || error.message}`)
        })
        .finally(() => { if (showLoader) this.loading = false })
    },
    async unitAdd (geometry) {
      const unit = await this.addZone({
        name: this.randomName('unit'),
        parent: this.floorId,
        level: 'unit',
        config: { geometry }
      })
      this.units.push(unit)
      return this.refreshMarkers()
    },
    async unitUpdate ({ id, geometry = null }) {
      const unit = await this.updateZone({
        id,
        config: { geometry }
      })
      const index = this.units.findIndex(u => u.id === id)
      this.$set(this.units, index, unit)
      return this.refreshMarkers()
    },
    edit (options) {
      if (this.map && this.map.isFullscreen()) {
        this.$refs.editModal.open(options)
      } else {
        this.editModal(options)
      }
    },
    async unitEdit (id) {
      const unit = this.zone(id)
      const isActive = (unit.config || {}).state === 'active'
      this.edit({
        title: 'Edit Unit',
        fields: [
          { id: 'name', name: 'Name', placeholder: 'Enter name of unit', value: unit.name },
          { name: 'Settings' },
          { id: 'active', name: 'Active', value: isActive, type: 'toggle' }
        ],
        onSubmit: async ({ name, active }) => {
          if (!name) {
            throw new Error('Must specify a name')
          }
          // If deactivating an active unit, show confirmation prompt. In all other cases, update directly.
          if (active === true || !isActive ||
              confirm(`Are you sure you want to deactivate unit ${name} and all areas within?`)) {
            try {
              const updatedUnit = await this.updateZone({
                id,
                name,
                ...(active !== isActive && { config: { ...unit.config, state: active ? 'active' : 'inactive' } })
              })
              const index = this.units.findIndex(u => u.id === id)
              this.$set(this.units, index, updatedUnit)
              await this.refreshMarkers()
            } catch (error) {
              throw new Error(`Sorry, could not edit unit: ${error.reason || error.message}`)
            }
          }
        }
      })
    },
    async markerAdd ({ lat, lng, location }, { showLoader } = {}) {
      if (!this.editable) return
      const coordinates = { lat, lng }
      location = this.locationById(location) || this.locationTo(this.floorId)
      await this.markerAddWithLocation({ coordinates, location }, showLoader)
    },
    async zoneCheck (unitId, area, areaName) {
      if (area) {
        if (area.parent === unitId) {
          if (area.name !== areaName) {
            await this.updateZone({ id: area.id, name: areaName })
          }
        } else {
          await this.updateZone({ id: area.id, name: areaName, parent: unitId })
        }
      } else {
        area = await this.addZone({ name: areaName, parent: unitId })
      }
      await this.loadPath({ zone: area.id })
      return this.locationTo(area.id)
    },
    async markerEdit (marker) {
      if (!this.editable) return
      try {
        if (this.isGateway(marker)) {
          return this.gatewayEdit(marker)
        }
        if (Array.isArray(marker.config.location)) {
          await this.loadPath({ zone: marker.config.location.slice(-1)[0].id })
        }
        const currentUnit = marker.config.location[this.levelDepth('unit')]
        const currentArea = marker.config.location[this.levelDepth('area')]
        const outletEnabled = (marker.config.location.slice(-1)[0].config || {}).outlet !== false
        const currentLabels = ((marker.config || {}).labels || []).map(l => ({ id: l, title: markerLabels[l] }))
        this.edit({
          title: 'Assign Gateway',
          fields: [
            { id: 'gateway', name: 'MAC Address', placeholder: 'Enter or scan MAC address', list: (query) => this.assignableGateways(query), freeform: true, qr: true, regex: this.macRegEx, regexTruncateLength: this.macLength, focused: true },
            { id: 'unit', name: 'Unit', value: currentUnit && currentUnit.name, disabled: true },
            { id: 'area', name: 'Area', placeholder: 'Enter area name', value: currentArea && currentArea.name },
            { id: 'labels', name: 'Labels', placeholder: 'Add labels', value: currentLabels || [], options: Object.entries(markerLabels).map(([k, v]) => ({ id: k, title: v })), type: 'suggestMulti' },
            { id: 'notes', name: 'Notes', placeholder: 'Enter notes', value: marker.config.notes },
            { name: 'Power Outlet' },
            { id: 'outlet', name: 'Enable', value: outletEnabled, type: 'toggle' }
          ],
          onSubmit: async ({ gateway, area, labels, notes, outlet }) => {
            if (!area) {
              throw new Error('Must specify area')
            }
            if (gateway && gateway.id) {
              // handle suggest result which is of form { id, title }
              gateway = gateway.id
            }
            if (gateway) {
              gateway = gateway.toLowerCase()
            }
            try {
              if (currentArea.name !== area || outletEnabled !== outlet) {
                await this.updateZone({ id: currentArea.id, name: area, config: { outlet } })
              }
              const changes = {
                location: this.locationById(marker.config.location),
                coordinates: {
                  ...marker.config.coordinates
                },
                labels: labels.map(l => l.id),
                notes
              }
              await this.markerUpdate(marker, changes)
              if (gateway) {
                const { data: existing } = await api.get(`gateways/${gateway}`)
                if (existing && existing.config && existing.config.coordinates) {
                  alert('This gateway is already assigned to another location')
                  return
                }
                await api.post(`markers/${marker.id}/assign/${gateway}`)
              }
              return this.refreshMarkers()
            } catch (error) {
              throw new Error(`Sorry, could not edit marker: ${error.reason || error.message}`)
            }
          }
        })
      } catch (error) {
        console.log(error)
        alert(`Sorry, could not edit marker: ${error.reason || error.message}`)
      }
    },
    async gatewayEdit (gateway) {
      const options = [
        { text: 'No action' },
        { value: 'uninstall', text: 'Uninstall' },
        { value: 'factory-reset', text: 'Uninstall & Factory Reset' },
        { value: 'rma', text: 'Uninstall & RMA' },
        { value: 'lost', text: 'Uninstall & Mark as Lost' }
      ]
      const unit = gateway.config.location[this.levelDepth('unit')]
      const currentArea = gateway.config.location[this.levelDepth('area')]
      const outletEnabled = (gateway.config.location.slice(-1)[0].config || {}).outlet !== false
      const currentLabels = ((gateway.config || {}).labels || []).map(l => ({ id: l, title: markerLabels[l] }))
      this.edit({
        title: 'Edit Gateway',
        fields: [
          { id: 'id', name: 'MAC Address', value: gateway.id, readonly: true },
          { id: 'unit', name: 'Unit', value: unit && unit.name, disable: true },
          { id: 'area', name: 'Area', value: currentArea && currentArea.name },
          { id: 'action', name: 'Actions', options },
          { id: 'labels', name: 'Labels', placeholder: 'Add labels', value: currentLabels, options: Object.entries(markerLabels).map(([k, v]) => ({ id: k, title: v })), type: 'suggestMulti' },
          { id: 'notes', name: 'Notes', value: gateway.config.notes, placeholder: 'Enter notes' },
          { name: 'Power Outlet' },
          { id: 'outlet', name: 'Enable', value: outletEnabled, type: 'toggle' }
        ],
        onSubmit: async ({ action, labels, notes, area, outlet }) => {
          if (!area) {
            throw new Error('Must specify unit and area')
          }
          const changes = {
            location: this.locationById(gateway.config.location),
            labels: labels.map(l => l.id),
            notes
          }
          if (area !== currentArea.name || outletEnabled !== outlet) {
            await this.updateZone({ id: currentArea.id, name: area, config: { outlet } })
          }
          await this.gatewayUpdate(gateway, changes)
          if (action) {
            await api.post(`gateways/${gateway.id}/unassign`, { action, rmaReason: '' })
          }
          return this.refreshMarkers()
        }
      })
    },
    gatewayUpdate (gateway, changes) {
      return api.put(`gateways/${gateway.id}`, changes)
        .then(() => {
          if ('coordinates' in changes && !changes.coordinates) {
            return this.markerAdd({
              ...gateway.config.coordinates,
              location: gateway.config.location
            })
          } else {
            return this.refreshMarkers()
          }
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not edit gateway: ${error.reason || error.message}`)
        })
    },
    markerUpdate (marker, changes) {
      return api.put(`markers/${marker.id}`, changes)
        .then(() => {
          return this.refreshMarkers()
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not edit marker: ${error.reason || error.message}`)
        })
    },
    markerDelete (marker, showLoader) {
      if (showLoader) this.loading = true
      return api.delete(`markers/${marker.id}`)
        .then(() => {
          return this.refreshMarkers()
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not remove marker: ${error.reason || error.message}`)
        })
        .finally(() => { if (showLoader) this.loading = false })
    },
    async markerMove (marker, { lat, lng }) {
      if (!this.editMarkersMode || !lng || !lat) return
      const changes = {
        coordinates: { lat, lng },
        location: this.locationById(marker.config.location) || this.locationTo(this.floorId)
      }
      const prompt = 'Are you sure you want to move this gateway?'
      if (this.isGateway(marker)) {
        if (confirm(prompt)) {
          await this.gatewayUpdate(marker, changes)
        }
      } else {
        await this.markerUpdate(marker, changes)
      }
    },
    markerIcon (marker) {
      let icon = ''
      // In edit markers mode, both markers and gateways are always blue.
      // Otherwise, the default is gray and more colors are shown for gateways based on other conditions
      let markerColor = this.editMarkersMode ? 'blue' : 'gray'
      if (this.isGateway(marker)) {
        const status = marker.state && marker.state.status
        icon = 'microchip'
        // If the user has permission to view gateway status and we are not in edit-markers mode, more detailed rules apply.
        // Otherwise, gateway markers are all blue.
        if (!this.editMarkersMode && this.$can('read', 'Gateway', 'status')) {
          // Show a simple blue/red if the user cannot see real-time status, otherwise show more info through the colors.
          if (this.$can('read', 'Gateway', 'realTimeStatus')) {
            if (status === 'disconnected') markerColor = 'red'
            else if (this.hasPowerProblems(marker)) markerColor = 'pink'
            else if (status.startsWith('ota')) markerColor = 'blue'
            else if (this.isSafeMode(marker)) markerColor = 'darkblue'
            else if (marker.config.state === 'deployed') {
              if ((marker.state || {}).adminMode) {
                markerColor = 'darkgreen'
              } else {
                markerColor = this.checkDiagnostics(marker) ? 'green' : 'orange'
              }
            }
          } else {
            if (marker.isConnected) {
              markerColor = 'blue'
            } else {
              markerColor = 'red'
            }
          }
        } else {
          markerColor = 'blue'
        }
      }
      return L.AwesomeMarkers.icon({ prefix: 'fa', icon, markerColor })
    },
    trackingMarkerIcon ({ icon, markerColor = 'darkblue' }) {
      return L.AwesomeMarkers.icon({ prefix: 'fa', icon, markerColor })
    },
    personIcon (person = {}) {
      return L.AwesomeMarkers.icon({ prefix: 'fa', icon: 'user-nurse', markerColor: person.color || 'red' })
    },
    diagBadge (label) {
      if (label === 'Diagnostics OK') {
        return `<span class="p-1 mr-1 diag badge badge-pill badge-success">${label}</span>`
      } else {
        return `<span class="p-1 mr-1 diag badge badge-pill badge-danger">${label}</span>`
      }
    },
    diagnosticsSummary (marker) {
      let label = 'Diagnostics OK'
      if (marker.state.status === 'disconnected') {
        label = 'Gateway down'
      } else {
        const diagResult = this.checkDiagnostics(marker, true)
        if (!diagResult.button || !diagResult.coverage || !diagResult.floor) {
          label = diagResult.button && diagResult.coverage ? 'Floor failures' : 'Coverage failures'
        }
      }
      return `${this.diagBadge(label)}`
    },
    neighbors (marker) {
      const gateway = (this.isGateway(marker) && marker) || {}
      const { coordinates: start } = gateway.config || {}
      if (!start) return []
      const { diagnostics = {} } = gateway.state || {}
      const neighbors = Object.entries(diagnostics.neighbors || {})
      const onFloor = neighbors
        .filter(([id, distance]) => !!this.markersMap[id])
        .map(([id, distance]) => {
          const neighbor = this.markersMap[id] || {}
          const { coordinates } = neighbor.config || {}
          return coordinates && { id, distance, line: [start, coordinates] }
        })
      const offFloor = neighbors
        .filter(([id, distance]) => !this.markersMap[id])
        .map(([id, distance]) => ({ id, distance }))
      return { onFloor, offFloor }
    },
    isSafeMode (gateway) {
      return ((gateway.state || {}).version || {}).isSafeMode
    },
    hwLabel (gateway) {
      const version = {
        v2: '2',
        v3: '3'
      }[((gateway.state || {}).hardware || {}).revision] || '?'
      return `Version: ${version}`
    },
    hasPowerProblems (gateway) {
      return (((gateway.state || {}).debug || {}).rebootInfo || {}).lastResetReason === BROWNOUT_RESET_CODE || 0
    },
    async generateThumbnail ({ id, path }) {
      try {
        const { data } = await api.post(`/zones/${this.floorId}/plan/thumbnail`, { id, path })
        const { job } = data
        let status = { isRunning: true }
        this.updateProgress({
          step: 'Processing image...',
          percent: 0,
          cancel: false
        })
        while (status.isRunning && this.progress) {
          const { data } = await api.get(`/zones/${this.floorId}/plan/status/${job}`)
          status = data
          if ('percent' in status) {
            this.updateProgress({
              step: status.step,
              percent: status.percent,
              cancel: false
            })
          }
          if (status.isRunning) {
            await this.delay(1000)
          }
        }
        if (!this.progress) {
          return
        }
        if (status.failReason) {
          throw new Error(status.failReason)
        }
        if (this.progress.percent !== 100) {
          throw new Error('Prematurely stopped')
        }
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert(`Sorry, there was a problem: ${error.message}`)
      } finally {
        this.updateProgress()
      }
    },
    async generateTiles () {
      try {
        const plan = this.$refs.editPlanLayer
        const floor = this.zone(this.floorId)
        const planId = floor && floor.config && floor.config.planId
        const editId = this.editPlan.id
        const { data } = await api.post(`/zones/${this.floorId}/plan/tile`, {
          ...(planId === editId ? { sourceId: planId } : { id: editId }),
          corners: plan.getCorners()
        })
        const { job, id } = data
        let status = { isRunning: true }
        this.updateProgress({
          step: 'Saving Changes...',
          percent: 0,
          cancel: false
        })
        while (status.isRunning && this.progress) {
          const { data } = await api.get(`/zones/${this.floorId}/plan/status/${job}`)
          status = data
          if ('percent' in status) {
            this.updateProgress({
              step: status.step,
              percent: status.percent,
              cancel: false
            })
          }
          if (status.isRunning) {
            await this.delay(1000)
          }
        }
        if (!this.progress) {
          return
        }
        if (status.failReason) {
          throw new Error(status.failReason)
        }
        if (this.progress.percent !== 100) {
          throw new Error('Prematurely stopped')
        }
        await this.updateZone({
          id: this.floorId,
          config: {
            planId: id,
            geometry: plan.getGeometry()
          }
        })
        await this.updatePlan()
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert(`Sorry, there was a problem: ${error.message}`)
      } finally {
        this.updateProgress()
      }
    },
    async updateBearing (degrees) {
      this.loading = true
      try {
        if (typeof degrees !== 'number') return
        await this.updateZone({
          id: this.floorId,
          config: {
            planBearing: degrees
          }
        })
      } catch (error) {
        console.log(error)
        this.loading = false
        alert(`Sorry, there was a problem: ${error.message}`)
      } finally {
        this.loading = false
      }
    },
    async upload ({ target }) {
      if (!this.floorId) return
      const file = target.files[0]
      const formData = new FormData()
      formData.append('floorplan', file)
      try {
        this.updateProgress({
          step: 'Uploading image...',
          percent: 0,
          cancel: false
        })
        const { data } = await api.post(`/zones/${this.floorId}/plan/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progress) => {
            if (progress.lengthComputable) {
              this.updateProgress({
                step: 'Uploading image...',
                percent: Math.ceil(100 * progress.loaded / progress.total),
                cancel: false
              })
            }
          },
          timeout: 600000
        })
        await this.generateThumbnail(data)
        await this.showPlanEditor(data.id)
        this.planEdited = true
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert(`Sorry, there was a problem uploading: ${error.reason || error.message}`)
      } finally {
        target.value = ''
        this.updateProgress()
      }
    },
    move (place, geocoder) {
      if (!geocoder || !geocoder.geometry) return
      const { viewport } = geocoder.geometry
      const ne = viewport.getNorthEast()
      const sw = viewport.getSouthWest()
      this.fit([ne.toJSON(), sw.toJSON()])
    },
    fit (bounds, options) {
      if (bounds) {
        this.map.fitBounds(bounds, options)
      }
    },
    async assignableGateways (query) {
      if (query.length < 4) return []
      const params = {
        query: JSON.stringify({
          _id: query,
          'config.state': 'ready_to_deploy',
          ...(((this.zone(this.floorId) || {}).config || {}).category === 'outdoor' && { sku: 'NODE1', 'config.logistics.sku': { $in: ['NODE1', 'GWL1'] } })
        }),
        limit: 20,
        byColumn: 1,
        orderBy: '_id'
      }
      const { data } = await api.get('gateways', { params })
      if (data && data.data && data.data.length) {
        return data.data.map(gateway => ({
          id: gateway.id,
          title: gateway.id
        }))
      }
      return []
    },
    onMarkerTap (marker, event) {
      if (this.editMarkersMode) {
        if (this.isMarker(marker)) {
          this.toolbarOpen(event.latlng, { type: 'marker', marker })
        } else {
          this.markerEdit(marker)
        }
      } else if (this.diag && this.isGateway(marker)) {
        this.diagDetailsGateway = marker.id
      }
    },
    onUnitTap ({ unit, latLng }) {
      if (this.editMarkersMode) {
        this.toolbarOpen(latLng, { type: 'unit', unit })
      }
    },
    toolbarOpen (coordinates, target, offset) {
      this.toolbarTarget = target
      this.toolbarOffset = offset || 0
      this.$refs.toolbar.mapObject.openPopup(coordinates)
    },
    toolbarClose () {
      this.$refs.toolbar.mapObject.closePopup()
      this.toolbarTarget = null
    },
    toolbarAction (action) {
      const target = this.toolbarTarget
      this.toolbarClose()
      const { type } = target
      if (type === 'marker') {
        const { marker } = target
        if (action === 'edit') {
          this.markerEdit(marker)
        } else if (action === 'delete') {
          this.markerDelete(marker)
        }
      } else if (type === 'unit') {
        const editor = this.$refs.unitEditor
        const { unit } = target
        editor.delete(unit.id)
      }
    },
    onPlanEdit () {
      this.planEdited = true
    },
    onPlanRotate () {
      this.planRotated = true
    },
    async fetchGatewayMetrics () {
      if (!this.showMarkers || !this.showControls) return
      this.refreshing = true
      let metrics = {}
      try {
        if (this.floorId) {
          const now = new Date()
          const start = new Date(now.getTime() - 24 * 3600 * 1000).toISOString()
          const { data } = await api.get(`metrics/gateways?zone=${this.floorId}&start=${start}&end=${now.toISOString()}`)
          metrics = data
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.gatewayMetrics = metrics
        this.refreshing = false
      }
    },
    checkDiagnostics (marker, fullResult) {
      const metrics = this.gatewayMetrics[marker.id]
      if (metrics) {
        const button = metrics.button.percent >= BUTTON_PERCENT_THRESHOLD
        const coverage = metrics.coverage.percent >= COVERAGE_PERCENT_THRESHOLD
        const floor = metrics.floor.percent >= FLOOR_PERCENT_THRESHOLD
        return fullResult ? { button, coverage, floor } : button && coverage && floor
      }
      const { button, coverage, floor } = (marker.state || {}).diagnostics || {}
      return fullResult ? { button, coverage, floor } : button && coverage && floor
    },
    updateProgress (progress) {
      this.progress = progress && { ...progress }
    },
    showFilterModal () {
      const typeOptions = [
        {
          id: 'all',
          label: 'All types',
          children: [
            { id: 'markers', label: 'Unassigned markers' },
            {
              id: 'gateways',
              label: 'Gateways',
              children: [
                { id: 'connectedDays', label: 'Connected' },
                {
                  id: 'disconnected',
                  label: 'Disconnected',
                  children: [
                    { id: 'disconnectedDays', label: `Disconnected > ${this.disconnectedDays} day(s)` },
                    { id: 'disconnectedRecent', label: `Disconnected < ${this.disconnectedDays} day(s)` }
                  ]
                },
                { id: 'safeMode', label: 'Safe Mode' },
                { id: 'diagFail', label: 'Diagnostics failures' },
                { id: 'healthy', label: 'Healthy' }
              ]
            }
          ]
        }
      ]
      const labelOptions = [
        {
          id: 'all',
          label: 'All pins',
          children: [
            {
              id: 'labeled',
              label: 'All labeled',
              children: Object.entries(markerLabels).map(([id, label]) => ({ id, label }))
            },
            { id: 'notes', label: 'Has notes' },
            { id: 'unlabeled', label: 'No labels or notes' }
          ]
        }
      ]
      this.edit({
        title: 'Select filter for pins',
        fields: [
          { id: 'markerTypeFilter', name: 'Filter by pin type', value: this.markerTypeFilter, options: typeOptions, type: 'tree', multiple: true, searchable: false, closeOnSelect: false, expandLevel: Infinity },
          { id: 'markerLabelFilter', name: 'Filter by pin labels & notes', value: this.markerLabelFilter, options: labelOptions, type: 'tree', multiple: true, searchable: false, closeOnSelect: false, expandLevel: Infinity }
        ],
        onSubmit: ({ markerTypeFilter, markerLabelFilter }) => {
          this.markerTypeFilter = markerTypeFilter
          this.markerLabelFilter = markerLabelFilter
        }
      })
    },
    flyTo (latlng, zoom = 21, options = {}) {
      this.map.flyTo(latlng, zoom, options)
    }
  }
}
</script>

<style>
  .vue2leaflet-map {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
  }
  .leaflet-popup-content {
    padding: 2px;
    margin: 0px;
  }
  .autocomplete-field {
    z-index: 1000;
    width: 400px;
  }
  .leaflet-top {
    z-index: 900;
  }
  .leaflet-control-container .leaflet-top,
  .leaflet-control-container .leaflet-bottom {
    transform: translate3d(0px, 0px, 0px);
    will-change: transform;
  }
</style>

<style scoped>
  .marker {
    font-size: 36px;
  }
  .vue2leaflet-map {
    cursor: crosshair;
  }
  .refresh-button {
    z-index: 9999;
  }
  .progress-control {
    width: 30%;
    z-index: 9999;
    margin-top: 10px;
    margin-left: 10px;
  }
</style>
