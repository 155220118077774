<template>
  <item-list :tableId="itemTableId" :section="section" :title="title || ''"
    :columns="itemColumns" :options="options" :items="rows" :loading="loading"
    :csv="csv" :csvFetch="csvFetch" :csvDefaultValue="0"
    @refresh="refresh(true)">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <div v-if="row.rename !== null" slot="zone.name" slot-scope="{ row  }"
      :style="`padding-left: ${indent(row)}px;`"
      >
      <i class="arrow fas fa-caret-right fa-hidden"></i>
      <input
        :ref="`input-${row.id}`"
        type="text"
        v-model="row.rename"
        @keydown.enter.prevent="$emit('save', row)"
        @keydown.escape.stop="$emit('cancel', row)"
        @blur.stop="$emit('cancel', row)"
      />
    </div>
    <span v-else slot="zone.name" slot-scope="{ row  }" class="nobreak"
      :style="`padding-left: ${indent(row)}px;`"
      @click.stop="onToggle(row)"
      >
      <i v-if="expanded.find(id => id === row.zone.id)" class="arrow fas fa-sort-down"></i>
      <i v-else-if="!row.zone.isLeaf" class="arrow fas fa-caret-right"></i>
      <i v-else class="arrow fas fa-caret-right fa-hidden"></i>
      {{ row.zone.name }}
      <span v-if="_get(row, 'zone.followers.length')" class="peered">
        FOLLOWED <i class="fa fa-link"></i>
      </span>
    </span>
  </item-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/mixins/utils'
import api from '@/lib/api'
import { config } from '@/lib/zones'
import _ from 'lodash'

export default {
  mixins: [utils],
  props: [
    'tableId',
    'section',
    'title',
    'columns',
    'headings',
    'query',
    'editable',
    'csv'
  ],
  data () {
    return {
      expanded: [],
      itemColumns: [
        'zone.name',
        ...(this.columns || [])
      ],
      options: {
        headings: {
          'zone.name': 'Name',
          ...this.headings
        },
        orderBy: { column: 'id', ascending: true },
        sortable: [],
        filterable: [],
        columnsClasses: { 'zone.name': 'zone-name', actions: 'actions' },
        perPage: 200,
        perPageValues: [200]
      },
      rows: [],
      idMap: {},
      ...config
    }
  },
  async mounted () {
    if (!this.user) return
    await this.loadPath({ zone: this.user.zone.id })
    await this.refresh()
  },
  watch: {
    query () {
      if (this.rows.length && this.query) {
        this.refresh(true)
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('zones', [
      'zone',
      'subzones',
      'loading'
    ]),
    itemTableId () {
      return this.tableId && `${this.tableId}-${this.user.id}`
    }
  },
  methods: {
    // TODO
    // - [ ] replace with require('lodash/get') ...
    _get (obj, path, defaultValue) {
      return _.get(obj, path, defaultValue)
    },
    ...mapActions('zones', {
      clearZones: 'clear',
      loadPath: 'loadPath'
    }),
    async refresh (keepExpanded) {
      this.clearZones()
      if (keepExpanded) {
        const valid = []
        for (const id of this.expanded) {
          await this.loadPath({ zone: id, ...this.query })
          const zone = this.zone(id)
          if (zone.isLeaf) break
          valid.push(id)
        }
        this.expanded = valid
      } else {
        const zone = this.user.zone.id
        await this.loadPath({ zone, ...this.query })
        this.expanded = [zone]
        this.idMap = { [zone]: '0' }
      }
      this.apply()
    },
    apply () {
      const root = this.zone(this.user.zone.id)
      let index = 0
      const rows = [{
        id: this.idMap[root.id],
        zone: root,
        indent: 0,
        rename: null,
        index: index++
      }]
      this.expanded.forEach((zoneId, indent) => {
        const children = this.subzones(zoneId) || []
        rows.push(...[...children].sort(this.zoneSort).map((zone, i) => {
          const id = this.idMap[zone.id] = `${this.idMap[zoneId]}.${String(i).padStart(3, 0)}`
          return {
            id,
            zone,
            parent: this.zone(zone.parent),
            indent: indent + 1,
            rename: null,
            index: index++
          }
        }))
      })
      this.rows = rows
    },
    async expand (id) {
      const zone = this.zone(id) || {}
      if (!zone) return
      if (!zone.isLoaded) {
        await this.loadPath({ zone: id, ...this.query })
      }
      if (id === this.user.zone.id) {
        this.expanded = [id]
      } else {
        const index = this.expanded.findIndex(id => id === zone.parent)
        if (index === -1) return
        this.expanded = [...this.expanded.slice(0, index + 1), id]
      }
      this.apply()
    },
    collapse (id) {
      const index = this.expanded.findIndex(zoneId => zoneId === id)
      if (index === -1) return
      this.expanded = this.expanded.slice(0, index)
      this.apply()
    },
    onToggle (row) {
      if (row.zone.isLeaf) return
      if (this.expanded.find(id => id === row.zone.id)) {
        this.collapse(row.zone.id)
      } else {
        this.expand(row.zone.id)
      }
    },
    indent (row) {
      return 40 * row.indent
    },
    async csvFetch () {
      const report = this.query.report
      const params = {
        ...this.query,
        admin: true,
        'report.type': report.type,
        'report.start': report.start,
        'report.end': report.end,
        'report.level': 'unit'
      }
      delete params.report
      const { data } = await api.get(`zones/${this.user.zone.id}`, { params }).catch(error => console.error(error))
      const reports = data.reports || {}
      const results = Object.entries(reports).map(([zoneId, report]) => ({
        zone: { id: zoneId, report }
      }))
      return results
    }
  }
}
</script>

<style>
.zone-name {
  cursor: pointer;
  outline: none;
}
</style>

<style scoped>
i.arrow {
  width: 10px;
  font-size: 10pt;
}
.fa-hidden {
  color: transparent;
}

.peered {
  font-family: Monaco,Menlo,Consolas,Bitstream Vera Sans Mono,monospace;
  color: #720e9e;
  font-weight: bold;
}

</style>
