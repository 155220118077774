import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import alerts from './modules/alerts'
import zones from './modules/zones'
import provision from './modules/provision'
import distributionStation from './modules/distribution.station'
import pwa from './modules/pwa'
import tagBatchOps from './modules/tag.batch.ops'
import gatewayBatchOps from './modules/gateway.batch.ops'
import orderRecipients from './modules/order.recipients'
import analytics from './modules/analytics'
import assets from './modules/assets'
import { detectDomain, isDomain } from '@/lib/util'

Vue.use(Vuex)

const STORE_DOMAINS_IN_LOCAL_STORAGE = true
const STORE_SLUGS_IN_LOCAL_STORAGE = true

const domainStorage = STORE_DOMAINS_IN_LOCAL_STORAGE ? localStorage : sessionStorage
const slugStorage = STORE_SLUGS_IN_LOCAL_STORAGE ? localStorage : sessionStorage

const detectedDomain = detectDomain() || 'strongline'
const slugKey = `${window.location.hostname}:${detectedDomain}:slug`
const domainKey = `${window.location.hostname}:${detectedDomain}:domain`

function getSlug () {
  const { hostname } = document.location
  const storedSlug = slugStorage[slugKey]
  const hostTip = isDomain(hostname.split('.')[0]) ? '' : hostname.split('.')[0]
  const hostSlug = hostTip === 'localhost' ? 'admin' : hostTip
  const query = new URLSearchParams(window.location.search)
  const querySlug = query.get('authSlug')
  const slug = querySlug || hostSlug || storedSlug || ''
  if (slug) {
    slugStorage[slugKey] = slug
  }
  return slug
}

function getDomain () {
  const query = new URLSearchParams(window.location.search)
  const queryDomain = query.get('authDomain')
  let domain = detectDomain()
  if (queryDomain) {
    domainStorage[domainKey] = queryDomain
  } else if (!domain && domainStorage[domainKey]) {
    domain = domainStorage[domainKey]
  }
  return domain || 'strongline'
}

const state = {
  connected: true,
  muted: false,
  alertsMuted: false,
  slug: getSlug(),
  domain: getDomain(),
  consoleConfig: null
}

const getters = {
  connected: state => state.connected,
  muted: state => state.muted,
  alertsMuted: state => state.alertsMuted,
  slug: state => state.slug,
  domain: state => state.domain,
  consoleConfig: state => state.consoleConfig
}

const actions = {
  setMuted: async ({ commit }, isMuted) => {
    commit('SET_MUTED', isMuted)
  },
  setAlertsMuted: async ({ commit }, isMuted) => {
    commit('SET_ALERTS_MUTED', isMuted)
  }
}

const mutations = {
  SET_CONNECTED (state, isConnected) {
    state.connected = isConnected
  },
  SET_MUTED (state, isMuted) {
    state.muted = isMuted
  },
  SET_ALERTS_MUTED (state, isMuted) {
    state.alertsMuted = isMuted
  },
  SET_SLUG (state, slug) {
    if (slug) {
      slugStorage[slugKey] = slug
    } else {
      delete slugStorage[slugKey]
      slug = getSlug()
    }
    state.slug = slug
  },
  SET_DOMAIN (state, domain) {
    if (domain) {
      domainStorage[domainKey] = domain
    } else {
      delete domainStorage[domainKey]
      domain = getDomain()
    }
    state.domain = domain
  },
  UPDATE_CONSOLE_CONFIG (state, config) {
    state.consoleConfig = config
  }
}

export default new Vuex.Store({
  modules: {
    assets,
    analytics,
    auth,
    zones,
    alerts,
    provision,
    distributionStation,
    pwa,
    tagBatchOps,
    gatewayBatchOps,
    orderRecipients
  },
  state,
  getters,
  actions,
  mutations,
  strict: true
})
