import Vue from 'vue'
import { Event } from 'vue-tables-2'
import moment from 'moment'
import { parsePhoneNumber } from 'libphonenumber-js'
import { mapGetters } from 'vuex'
import api from '@/lib/api'
import { capitalCase } from 'change-case'
import flatten from 'flat'
import { logistics } from '../config'
import get from 'lodash/get'

const levelNames = {
  root: 'Root',
  org: 'Organization',
  site: 'Site',
  building: 'Building',
  floor: 'Floor',
  unit: 'Unit',
  area: 'Area'
}
const levelList = Object.keys(levelNames)

const batteryQueries = {
  healthy: JSON.stringify({ $gte: 2800 }),
  low: JSON.stringify({ $gte: 2600, $lt: 2800 }),
  depleted: JSON.stringify({ $gte: 0, $lt: 2600 }),
  unknown: JSON.stringify({ $not: { $gte: 0 } })
}

const batteryLevelDescriptions = {
  healthy: 'Healthy',
  low: 'Low',
  depleted: 'Depleted',
  unknown: 'Unknown'
}

const batteryLevelIcons = {
  healthy: 'fa fa-battery-three-quarters',
  low: 'fa fa-battery-quarter',
  depleted: 'fa fa-battery-empty',
  unstable: 'fa fa-exclamation-triangle',
  unknown: 'fa fa-exclamation-circle'
}

const batteryLevelEstimatedLifetimes = {
  low: '< 3 months',
  depleted: 'dead or dying',
  unknown: 'missing battery data'
}

const batteryLevelColors = {
  healthy: 'green',
  low: 'orange',
  depleted: 'red',
  unstable: 'red',
  unknown: 'gray'
}

const tagDistroStationTimeout = 3600 * 1000 // ms

const tagCollectionReasons = new Map([
  [
    'none',
    'Not flagged'
  ], [
    'any',
    'All needing collection'
  ], [
    'highUrgency',
    'Collect immediately'
  ], [
    'mediumUrgency',
    'Collect soon'
  ], [
    'noUrgency',
    'Collect regular'
  ], [
    'battery_depleted',
    'Battery depleted'
  ], [
    'tag_crash_high',
    'Tag Unstable (Urgent)'
  ], [
    'firmware_obsolete',
    'Obsolete Firmware'
  ], [
    'manual',
    'Manually flagged'
  ], [
    'battery_low',
    'Battery Low'
  ], [
    'tag_crash_low',
    'Tag Unstable'
  ], [
    'staff_turnover',
    'Staff Turnover'
  ], [
    'tag_replaced',
    'New tag assigned'
  ]
])

const tagCollectionUrgency = {
  0: 'Collect Immediately',
  1: 'Collect Soon',
  2: 'Collect When Possible'
}

const tagReplacementTimeline = {
  0: 'Overdue',
  1: '1 - 2 months (ASAP)',
  3: '2 - 4 months (forecast)'
}

const tagReplacementTimelineDesc = {
  0: 'Depleted battery, or low battery reported more than 2 months ago.',
  1: 'Low battery, replace within 2 months.',
  3: 'Likely needs to be replaced in 2-4 months based on battery lifetime.'
}

const macRegEx = /([a-fA-F0-9]{12})/
const macLength = 12

const tagConfigStates = {
  flashed: 'Flashed',
  available: 'Available',
  reserved: 'Reserved',
  deployed: 'Deployed',
  ready_for_return: 'Ready for Return',
  returned: 'Returned',
  lost: 'Lost'
}

const gatewayConfigStates = {
  deployed: 'Deployed',
  ready_to_deploy: 'Ready to Deploy',
  undeployed: 'Ready for Return',
  returned: 'Returned',
  lost: 'Lost',
  unknown: 'Unknown'
}

const alertCardColors = ['#0e7dcd', '#4f8c9d', '#fa718e', '#68ed99', '#943d80', '#4cf32c', '#c712e8', '#378811', '#e689eb', '#bce333', '#4859ea', '#f4d403', '#f612a8', '#b8deb7', '#b42716', '#20d8fd', '#5f3e3f', '#edcbde', '#0a4f4e', '#f37e2f', '#1c4585']

const skuDisplayText = {
  GW2: 'GW2',
  TAG3: 'TAG3 / TAG5 / TAG7',
  TAG5: 'TAG3 / TAG5 / TAG7',
  TAG7: 'TAG3 / TAG5 / TAG7'
}

const skuBoxQuantity = {
  TAG3: 20,
  TAG5: 20,
  TAG7: 20,
  TAG6: 50,
  GW2: 5
}

const skuBoxCount = {
  TAG3: 10,
  TAG5: 10,
  TAG7: 10,
  TAG6: 4,
  GW2: 20
}

const roleBlacklist = {
  superAdmin: ['default'],
  admin: ['default', 'superAdmin', 'factory', 'warehouse', 'warehouseAutomation', 'smpReportUser'],
  default: ['default', 'admin', 'superAdmin', 'factory', 'warehouse', 'warehouseAutomation', 'smpReportUser']
}

const logisticsOrgSlugs = ['smpwarehouse', 'factory', 'strongline-inventory']

const msInDay = 86400000

const behaviorModes = new Map([
  [
    'standard',
    'Standard'
  ], [
    'walkthrough',
    'Walkthrough'
  ], [
    'responder',
    'Responder'
  ]
])

export default {
  data () {
    return {
      levelNames,
      levels: levelList,
      batteryQueries,
      batteryLevelDescriptions,
      batteryLevelIcons,
      batteryLevelEstimatedLifetimes,
      batteryLevelColors,
      behaviorModes,
      gatewayConfigStates,
      tagConfigStates,
      tagDistroStationTimeout,
      tagCollectionReasons,
      tagCollectionUrgency,
      tagReplacementTimeline,
      tagReplacementTimelineDesc,
      macRegEx,
      macLength,
      alertCardColors,
      skuDisplayText,
      skuBoxQuantity,
      skuBoxCount,
      roleBlacklist,
      logisticsOrgSlugs,
      msInDay
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'roles',
      'refreshToken'
    ]),
    ...mapGetters('alerts', [
      'alerts'
    ]),
    features () {
      if (!this.user || !this.user.zone) return {}
      const { config = {} } = this.user.zone
      return config.featureFlags || {}
    }
  },
  methods: {
    saveSetting (key, value) {
      window.sessionStorage.setItem(key, value)
    },
    loadSetting (key) {
      return window.sessionStorage.getItem(key)
    },
    removeSetting (key) {
      window.sessionStorage.removeItem(key)
    },
    clearSettings () {
      window.sessionStorage.clear()
    },
    savedFilter (field) {
      const key = `vuetables_${this.tableId}`
      const value = window.sessionStorage.getItem(key)
      if (value) {
        const state = JSON.parse(value)
        return (state.filterValues || {})[field] || (state.customQueries || {})[field] || (state.query || {})[field] || null
      }
      return null
    },
    changeFilter (field, value) {
      const key = `vuetables_${this.tableId}`
      const storage = JSON.parse(window.sessionStorage.getItem(key) || '{}')
      storage.filterValues = storage.filterValues || {}
      storage.filterValues[field] = value
      window.sessionStorage.setItem(key, JSON.stringify(storage))
      Event.$emit(`vue-tables.${this.tableId}.filter::${field}`, value && (value.id || value))
    },
    path (location) {
      if (Array.isArray(location)) {
        return location
          .slice(1)
          .map(loc => loc ? loc.name : '')
          .join(' > ')
      }
    },
    zoneId (location) {
      if (Array.isArray(location)) {
        const zone = location.slice(-1)[0]
        return (zone && zone.id) || zone
      }
    },
    zoneName (location) {
      if (Array.isArray(location)) {
        const zone = location.slice(-1)[0]
        return zone && zone.name
      }
    },
    locationById (location) {
      return location && location.map(loc => loc.id || loc)
    },
    sameLocation (loc1, loc2) {
      if (loc1 && loc2) {
        return loc1.map(l => l.id).join() === loc2.map(l => l.id).join()
      }
    },
    zoneIndexAtLevel (location, level) {
      let index = -1
      if (!Array.isArray(location)) {
        return index
      }
      if (location[0] && location[0].level) {
        index = location.findIndex(l => l.level === level)
      }
      if (index === -1) {
        index = this.levelDepth(level)
      }
      return index
    },
    zoneAtLevel (location, level) {
      const index = this.zoneIndexAtLevel(location, level)
      if (index >= 0) {
        return location[index]
      }
    },
    zoneIdAtLevel (location, level) {
      const zone = this.zoneAtLevel(location, level)
      if (zone) {
        return zone.id || zone
      }
    },
    time (at, ago, format) {
      if (at) {
        const time = moment(at)
        return ago ? time.fromNow() : time.format(format || 'ddd, MMM D h:mm:ss A')
      }
      return ''
    },
    firestoreDate (at, ago) {
      return this.time(at._seconds * 1000, ago, 'ddd, MMM D YYYY')
    },
    delay (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    editModal (options) {
      Vue.prototype.$editModal.open(options)
    },
    infoModal (options) {
      Vue.prototype.$infoModal.open(options)
    },
    urlPatternModal (options) {
      Vue.prototype.$urlPatternModal.open(options)
    },
    formatPhone (text) {
      try {
        const phoneNumber = parsePhoneNumber(text, 'US')
        return phoneNumber.formatNational()
      } catch (error) {
        return text
      }
    },
    formatContacts ({ sms, email, ascom, connexall, vocera, wctp, tiger } = {}) {
      const text = []
      try {
        if (sms) {
          const phoneNumber = parsePhoneNumber(sms, 'US')
          text.push(phoneNumber.formatNational())
        }
        if (email) {
          text.push(email)
        }
        if (ascom) {
          text.push(`[Ascom] ${ascom}`)
        }
        if (connexall) {
          text.push(`[Connexall] ${connexall}`)
        }
        if (vocera) {
          text.push(`[Vocera] ${vocera}`)
        }
        if (wctp) {
          text.push(`[WCTP] ${wctp}`)
        }
        if (tiger) {
          text.push(`[TC] ${tiger}`)
        }
      } catch (error) {
        // ignore
      }
      return text.join(', ')
    },
    parsePhone (text) {
      try {
        const phoneNumber = parsePhoneNumber(text, 'US')
        return phoneNumber.number
      } catch (error) {
        throw new Error('Invalid phone number')
      }
    },
    levelName (id) {
      return levelNames[id]
    },
    levelDepth (level) {
      const userZone = this.user && this.user.location.slice(-1)[0]
      const userOffset = userZone ? levelList.indexOf(userZone.level) : 0
      return level in levelNames ? levelList.indexOf(level) - userOffset : -1
    },
    childLevel (id) {
      const index = levelList.indexOf(id)
      if (index > -1) {
        return levelList[index + 1]
      }
    },
    zoneSort (a, b) {
      if (a.level === 'floor' && b.level === 'floor') {
        if (!a || !a.config || typeof a.config.number !== 'number') {
          return 1
        }
        if (!b || !b.config || typeof b.config.number !== 'number') {
          return -1
        }
        return a.config.number - b.config.number
      }
      if (a.name < b.name) {
        return -1
      }
      if (b.name < a.name) {
        return 1
      }
      return 0
    },
    randomName (prefix) {
      return `${prefix}-${Math.floor(Math.random(1000) * 1000)}`
    },
    loginToUrlWithRole (location, slug, role, launchNewWindow) {
      const { protocol, hostname, host, pathname } = location
      let query = `?token=${this.refreshToken.token}`
      if (role) {
        query += `&role=${role}`
      }
      if (!slug) {
        return alert('Unable to login to this zone')
      }
      let newHref = null
      if (hostname === 'localhost') {
        newHref = `${location.href}${query}&authSlug=${slug}`
      } else {
        const parts = host.split('.')
        const newHost = [slug, ...parts.slice(1)].join('.')
        newHref = `${protocol}//${newHost}${pathname}${query}`
      }
      if (launchNewWindow) {
        window.open(newHref)
      } else {
        window.location.href = newHref
      }
    },
    hashCode (str) {
      return Math.abs(Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0))
    },
    async googleDownload (path) {
      const { data: url } = await api.get(`storage/link/${path}`)
      if (url) {
        window.open(url)
      }
    },
    async getTagLogs (tag, flagsOnly, startTime) {
      const query = {
        operation: 'update',
        ...(flagsOnly && { $or: [{ 'changes.config.collection': { $exists: true } }, { 'diff.config.collection': { $exists: true } }] }),
        ...(startTime && { at: { $gte: startTime } })
      }
      const { data: events } = await api.get(`log/audit?id=${tag}&query=${JSON.stringify(query)}&includeDetails=true&orderBy=at`)
      return events.data || []
    },
    capitalize (text) {
      return capitalCase(text)
    },
    async isTagReusable (tag, startTime) {
      const logs = await this.getTagLogs(tag, true, startTime)
      return !Object.entries(flatten(logs)).some(([k, v]) => k.includes('urgency') && v < 2)
    },
    parseRoutineId (id) {
      let [group, ...title] = id.split('_')
      if (!title.length) {
        title = [group]
        group = 'other'
      }
      title = title.join('_')
      return {
        group: capitalCase(group),
        title: capitalCase(title)
      }
    },
    setEditModal (modal) {
      Vue.prototype.$editModal = modal
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
    },
    getLocationConfig (location = [], defaultConfig) {
      let config = defaultConfig
      for (const zone of location) {
        config = { ...config, ...(zone && zone.config) }
      }
      return config
    },
    locationSpecificTrackerParams (location) {
      if ((location || []).some(loc => get(loc, 'config.category') === 'outdoor')) {
        return { radius: 16, zoom: 18 }
      } else {
        return {}
      }
    },
    twoMonthsAgo () {
      return Date.now() - (60 * 24 * 3600 * 1000)
    },
    getReplacementTimeline ({ collection }) {
      if (!collection) return 'NA'
      const flaggedAt = new Date(collection.at).getTime()
      if (collection.urgency === 0 || (collection.urgency === 1 && flaggedAt < this.twoMonthsAgo())) {
        return tagReplacementTimeline[0]
      } else if (collection.urgency < 2) {
        return tagReplacementTimeline[1]
      } else {
        return 'When Possible'
      }
    },
    getInventorySlug () {
      return logistics.inventorySlug[this.$domain] || ''
    }
  }
}
